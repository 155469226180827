import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { useForm } from "react-hook-form";
import { IoMdAdd, IoMdRemove } from "react-icons/io";
import MaterialMasterList from "./MaterialMasterList";
import { materialCreate, supplierNameUnderSupplierId, supplierListUnderMaterial, Scope3CategoryList, Rr_Component_List_Under_Material } from "../const";
import Swal from 'sweetalert2';

const MaterialMaster = () => {
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    const [rows, setRows] = useState([{ id: Date.now() }]);
    const [showResults, setShowResults] = useState(false)
    const [resultMessage, setResultMessage] = useState({});
    const [dropdownData, setDropdownData] = useState([]);
    const [scope3CategoryListdropdownData, setScope3CategoryListDropdownData] = useState([]);

    const [supplierNameDropdownValues, setSupplierNameDropdownValues] = useState([]);

    const [materialNameDropdownValues, setMaterialNameDropdownValues] = useState([]);

    const [showOtherInput, setShowOtherInput] = useState(false);

    const [inputList, setInputList] = useState([{ supplierID: "", supplierName: "" }]);
    const formRef = useRef(null);
    const [activeTab, setActiveTab] = useState('tab1');

    const handleAddRow = () => {
        setInputList([...inputList, { supplierID: "", supplierName: "" }]);
        // console.log("add", inputList)

    };

    const handleRemoveRow = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);

    };

    const onSubmit = (data, e) => {
        // console.log("supplierName", data)
        const { materialID, materialName, materialCategory, materialDescription, activityType, categoryId, materialNameNew } = data;

        // Map supplier IDs to supplier names based on the selected options
        const supplierIdList = inputList.map((row, index) => ({
            supplierId: row.supplierID,
            supplierName: row.supplierName
        }));
        let otherName;
        if (showOtherInput) {
            otherName = materialNameNew
        }
        else {
            otherName = materialName
        }
        // Assemble the final object
        const materialObject = {
            materialId: materialID,
            materialName: otherName,
            materialCategory: materialCategory,
            materialDescription: materialDescription,
            supplierIdList: supplierIdList,
            categoryId: categoryId,
            activityType: activityType
        };
        // console.log("Material Object:", materialObject);
        materialCreate(materialObject).then(res => {
            if (res.status === 200 && res.data[0].status.code === 'SUCCESS') {
                // console.log("11111", res)
                Swal.fire({
                    title: "Success",
                    text: res.data[0].status.message,
                    icon: "success"
                }).then((result) => {
                    if (result.isConfirmed) {
                        e.target.reset();
                        formRef.current.reset();
                        setInputList([{ supplierID: "", supplierName: "" }]);
                        setSupplierNameDropdownValues(Array(rows.length).fill(''));
                    }
                })
            }
        })
    }


    const handleRest = (e) => {
        formRef.current.reset();
        // e.target.reset();
        setInputList([{ supplierID: "", supplierName: "" }]);
    }

    const handleInputChange = async (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        // console.log("e111", list)
        if (name === 'supplierID') {
            // Fetch supplier name when supplierID is changed
            const supplierName = await supplierIdDropDownSelect(value);
            // console.log("supplierName", supplierName)
            list[index]['supplierName'] = supplierName;
        }
        setInputList(list);
    };


    useEffect(() => {
        console.log("efect", inputList)
        supplierListUnderMaterial().then(res => {
            if (res.status === 200 && res.data[0].status.code === 'SUCCESS') {
                setDropdownData(res.data[0].primary);
            }
        })
        Scope3CategoryList().then(res => {
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                const categories = res.data.primary;
                setScope3CategoryListDropdownData(categories);
                if (categories.length > 0) {
                    setValue('categoryId', categories[0].categoryId);
                }
            }
        })
        Rr_Component_List_Under_Material().then(res => {
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                setMaterialNameDropdownValues(res.data.primary);
            }
        })

    }, []);


    useEffect(() => {
        if (activeTab !== 'tab1') {
            reset();
        }
    }, [activeTab, reset]);


    const supplierIdDropDownSelect = async (supplierId) => {
        let datareturn
        await supplierNameUnderSupplierId({ "supplierId": supplierId }).then(res => {
            // console.log(".then(res => {", res)
            if (res.status === 200 && res.data.status.code === 'SUCCESS') {
                const data = res.data.primary[0];
                // console.log("res.data.primary[0].supplierName", res.data.primary[0], res.data.primary[0].supplierName)
                datareturn = data.supplierName
            }
        })
        return datareturn;
    }

    const handleMaterialNameChange = (event) => {
        // console.log("111")
        const selectedValue = event.target.value;
        if (selectedValue === 'other') {
            setShowOtherInput(true);
        } else {
            setShowOtherInput(false);
        }
    };

    return (
        <div className="planning">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Material Master</h2>
                </div>
            </div>
            <div className="card">
                <div class="card-body">
                    <Tabs activeHeaderStyle={{ background: 'transparent' }} onSelect={(index, label) => setActiveTab(label)}
                        selected={activeTab}>
                        <Tab label="Create Material Master" eventKey="tab1">
                            <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                                <div className='row'>
                                    <div className='col-md-9'>
                                        {showResults ? <Results key={Math.random()} message={resultMessage.message} error={resultMessage.error} /> : null}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>Material ID</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="Enter Material ID"
                                                name="materialID"
                                                {...register("materialID", {
                                                    required: "Please enter Material ID",

                                                })}
                                            />
                                            {errors.materialID && <span className="err-msg">{errors.materialID.message}</span>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>Material Name</label>
                                            {/* <input type="text" className="form-control form-control-sm" placeholder="Enter Material Name"
                                                name="materialName"
                                                {...register("materialName", {
                                                    required: "Please enter Material Name",
                                                })}
                                            /> */}
                                            <select
                                                className="form-control form-control-sm"
                                                name="materialName"
                                                {...register("materialName", {
                                                    required: "Please select Scope3 Category",
                                                    onChange: (e) => { handleMaterialNameChange(e) }
                                                })}
                                            >

                                                <option value="">-Select-</option>
                                                {materialNameDropdownValues.map(item => (
                                                    <option
                                                        key={item.componentId}
                                                        value={item.componentName}
                                                    >
                                                        {item.componentName}
                                                    </option>
                                                ))}
                                                <option value="other">Other</option>
                                            </select>
                                            {errors.materialName && <span className="err-msg">{errors.materialName.message}</span>}
                                        </div>
                                    </div>
                                    {showOtherInput && (
                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor>Enter Material Name</label>
                                                <input type="text" className="form-control form-control-sm" placeholder="Enter Material Name"
                                                    name="materialNameNew"
                                                    {...register("materialNameNew", {
                                                        required: "Please enter Material Name",

                                                    })}
                                                />
                                                {errors.materialNameNew && <span className="err-msg">{errors.materialNameNew.message}</span>}
                                            </div>
                                        </div>
                                    )
                                    }

                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>Material Category</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="Enter Material Category"
                                                name="materialCategory"
                                                {...register("materialCategory", {
                                                    required: "Please enter Material Category",

                                                })}
                                            />
                                            {errors.materialCategory && <span className="err-msg">{errors.materialCategory.message}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>Material Description</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="Enter Material Description"
                                                name="materialDescription"
                                                {...register("materialDescription", {
                                                    required: "Please enter Material Description",
                                                })}
                                            />
                                            {errors.materialDescription && <span className="err-msg">{errors.materialDescription.message}</span>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>Select Scope3 Category</label>
                                            <select
                                                className="form-control form-control-sm"
                                                name="categoryId"
                                                {...register("categoryId", {
                                                    required: "Please select Scope3 Category",
                                                })}
                                            >

                                                <option value="">-Select-</option>
                                                {scope3CategoryListdropdownData.map(item => (
                                                    <option
                                                        key={item.categoryId}
                                                        value={item.categoryId}
                                                    >
                                                        {item.categoryName}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.categoryId && <span className="err-msg">{errors.categoryId.message}</span>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>Select Activity Type</label>
                                            <select
                                                className="form-control form-control-sm"
                                                name="activityType"
                                                {...register("activityType", {
                                                    required: "Please select Activity Type",
                                                })}
                                            >
                                                <option selected value="Upstream">Upstream</option>
                                                <option value="Downstream">Downstream</option>
                                            </select>
                                            {errors.activityType && <span className="err-msg">{errors.activityType.message}</span>}
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <strong>Supplier Information</strong>
                                    </div>
                                    {inputList.map((row, index) => (
                                        <div className='col-md-12'>
                                            <div className='row m-3' key={index}>
                                                <div className='col-md-3'>
                                                    <div className="form-group">
                                                        <label>Supplier ID</label>
                                                        <select
                                                            className='form-control form-control-s'
                                                            name="supplierID"
                                                            placeholder="Select"
                                                            value={row.supplierID}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                        >
                                                            <option value="">-Select-</option>
                                                            {dropdownData.map(item => (
                                                                <option
                                                                    key={item.supplierId}
                                                                    value={item.supplierId}
                                                                >
                                                                    {item.supplierId} - {item.supplierName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <div className="form-group">
                                                        <label>Supplier Name</label>
                                                        <input
                                                            className="form-control form-control-s"
                                                            disabled
                                                            name="supplierName"
                                                            placeholder="Enter Last Name"
                                                            value={row.supplierName}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                        />
                                                    </div>
                                                </div>
                                                {inputList.length - 1 === index && (

                                                    <div className='col-md-1 mt-4' id="addMore" style={{ whiteSpace: 'nowrap' }}>
                                                        <button
                                                            type="button"
                                                            className="btn btn-block btn-outline-success btn-sm"
                                                            onClick={handleAddRow}
                                                        >
                                                            <IoMdAdd /> Add
                                                        </button>
                                                    </div>
                                                )}
                                                {inputList.length !== 1 && (

                                                    <div className='col-md-1 mt-4' id="addMore" style={{ whiteSpace: 'nowrap' }}>
                                                        <button
                                                            type="button"
                                                            className="btn btn-block btn-outline-danger btn-sm"
                                                            onClick={() => handleRemoveRow(index)}
                                                            style={{ width: "90px", whiteSpace: "nowrap" }}
                                                        >
                                                            <IoMdRemove /> Remove
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <input type="submit" value="Submit" class="btn btn-primary newBtn" />
                                        <input onClick={() => handleRest()} type="reset" value="Reset" class="btn btn-secondary newBtn" />
                                    </div>
                                </div>
                            </form>
                        </Tab>
                        <Tab label="Materials List" eventKey="tab2">
                            <MaterialMasterList></MaterialMasterList>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );


}

const Results = (props) => {
    let error, classes;
    if (props.error === false) {
        error = 'Success';
        classes = 'alert alert-success alert-dismissible fade show'
    } else {
        error = 'Error!';
        classes = 'alert alert-danger alert-dismissible fade show'
    }
    return (
        <div className="results">
            <div className={classes}>
                <strong>{error}</strong> {props.message}
                <button type="button" class="close" data-dismiss="alert">&times;</button>
            </div>
        </div>
    );
}

export default MaterialMaster;