import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { keycloak, loggedUserPartyInfo, createActualValue, linkedInredirect } from '../const';
import Loader from '../Loader';
import Swal from 'sweetalert2';

const Login = (props) => {

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    const [showErrorMessage, setShowErroMessage] = useState(false);
    const [errorMessage, setErroMessage] = useState('');
    const [showLoader, setShowLoader] = useState(false);


    const onSubmit = (data, e) => {
        // e.preventDefault();
        const body = {
            username: data.username,
            password: data.password,
            grant_type: "password",
            client_id: 'rw_viewer',
            // redirect_uri: "http://192.168.1.166:3001"
            // redirect_uri: "http://localhost:3001"
            redirect_uri: "https://dev.realgreen.realware.app"
        };

        keycloak(body).then(response => {
            // console.log("response", response.status);
            if (response.status === 200) {
                sessionStorage.setItem('token', response.data.access_token);
                console.log("response.data.access_token", response.data.access_token)
                loggedUserPartyInfo(response.data.access_token).then(resLoggedUserDetailas => {
                    // console.log("resLoggedUserDetailas", resLoggedUserDetailas);
                    if (resLoggedUserDetailas.status === 200) {
                        sessionStorage.setItem('token', response.data.access_token);

                        if (resLoggedUserDetailas.data.primary[0].partyType === "SUPPLIER") {
                            sessionStorage.setItem("User", "supplier")
                            props.history.push('/actualCO2eReport');
                        }
                        else if (resLoggedUserDetailas.data.primary[0].partyType === "SUPER ADMIN") {
                            sessionStorage.setItem("User", "SUPER ADMIN")
                            props.history.push('/activityIdentification');
                        }
                        else {
                            sessionStorage.setItem("User", "Admin")
                            props.history.push('/breaKUpList');
                        }
                        setShowLoader(true);
                        // if (resLoggedUserDetailas.data.primary[0].partyType === "SUPPLIER") {
                        // }
                        //     else if (resLoggedUserDetailas.data.primary[0].partyType === "SUPER ADMIN") {
                        // }

                    }

                })
            }
            else if (response.status === 401 || response.data.error === 'invalid_grant') {
                Swal.fire({
                    title: "Oops...",
                    text: response.data.error_description,
                    icon: "error"
                })
            }

        })
    }


    const redirectGoogleURL = () => {
        window.open("https://auth.innovation.realware.app/realms/REALGREEN/protocol/openid-connect/auth?client_id=rw_viewer&redirect_uri=https://dev.realgreen.realware.app/login&response_type=code&scope=openid&kc_idp_hint=linkedin-openid-connect", "_self");
    }


    useEffect(() => {
        console.log("2222")
        const url = window.location.href;
        console.log("url", url);
        const queryParams = new URLSearchParams(window.location.search);
        let code = queryParams.get('code');
        console.log("code", code);
        if (code !== null) {
            console.log("navigateURL")
            let obj = {
                accessCode: code
            }
            linkedInredirect(obj).then(response => {
                if (response.status === 200 && response.data.status === 'success') {
                    sessionStorage.setItem('token', response.data.token);
                    console.log("response.data.access_token", response.data.token)
                    loggedUserPartyInfo(response.data.token).then(resLoggedUserDetailas => {
                        if (resLoggedUserDetailas.status === 'success') {
                            sessionStorage.setItem('token', response.data.token);

                            if (resLoggedUserDetailas.data.primary[0].partyType === "SUPPLIER") {
                                sessionStorage.setItem("User", "supplier")
                                props.history.push('/actualCO2eReport');
                            }
                            else if (resLoggedUserDetailas.data.primary[0].partyType === "SUPER ADMIN") {
                                sessionStorage.setItem("User", "SUPER ADMIN")
                                props.history.push('/activityIdentification');
                            }
                            else {
                                sessionStorage.setItem("User", "Admin")
                                props.history.push('/breaKUpList');
                            }
                        }
                    })
                }

            })
        }
    }, []);


    // login(loginRequest).then(res => {
    //     if (res.data.status.code === 'SUCCESS') {
    //         setShowLoader(false)
    //         sessionStorage.setItem('partyType', data.partyType);
    //         sessionStorage.setItem('token', res.data.token.access_token)
    //         if (data.partyType === 'Supplier') {
    //             props.history.push('/supplierPo');
    //         } else {
    //             props.history.push('/dashboard');
    //         }
    //     } else {
    //         props.history.push('/login')
    //         setShowErroMessage(true)
    //         setErroMessage(res.data.status.message)
    //         setShowLoader(false)
    //     }
    // })


    return (

        <section className='login-page'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-7  login-left' style={{ minHeight: '100vh' }}>
                        <div className='left-content'>
                            {/* <div className='logo-placeholder'>
                                <span className='icon'>
                                    <img src='/dist/img/rgnew-login.png' width={250} alt="RealCollab Icon"></img>
                                </span>
                            </div> */}
                            {/* <div className='text'>
                                <p style={{ fontSize: 24 }}>
                                    Together let's take a big step today
                                </p>
                                <p>
                                    "Real-time traceability enables
                                    you to remove any bottle necks
                                    so your customers can receive products on time."
                                </p>
                            </div>
                            <div className='copy'>
                                <p>Copyrights 2023, Real Variable Digital Asset Services, All rights reserved</p>
                            </div> */}
                        </div>
                    </div>
                    <div className='col-md-5'>
                        <div className='login-placeholder'>
                            <h2 style={{ fontSize: "2rem" }}><stong>Welcome back!</stong></h2>
                            <p style={{ color: '#989898', marginBottom: 20 }}>Please enter your credentials to continue!</p>
                            {showErrorMessage ? <span className="err-msg" style={{ textAlign: 'center', fontSize: 16 }}>{errorMessage}</span> : ""}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {/* <label>Party Type</label>
                                <div className='mb-3'>
                                    <select
                                        className="form-control"
                                        aria-label="Default select example"
                                        {...register("partyType", { required: true })}
                                        name="partyType"
                                    >
                                        <option value="">Select</option>
                                        <option value="Supplier">Supplier</option>
                                        <option value="OEM">OEM</option>
                                    </select>
                                    {errors.partyType && <div className="err-msg">Please select Party Type</div>}
                                </div> */}
                                <label>User Name / Email</label>
                                <div className='mb-3'>
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="User ID" name="username"
                                            {...register("username", { required: true })}
                                        />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fa fa-user-circle" />
                                            </div>
                                        </div>

                                    </div>
                                    {errors.username && <div className="err-msg">Please enter User Name/Email</div>}
                                </div>

                                <label>Password</label>
                                <div className='mb-3'>
                                    <div className="input-group">
                                        <input type="password" className="form-control" placeholder="Password" name="password"
                                            {...register("password", { required: true })}
                                        />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-lock" />
                                            </div>
                                        </div>
                                    </div>
                                    {errors.password && <div className="err-msg">Please enter password</div>}
                                </div>
                                <div className="row" style={{ marginBottom: 15 }}>
                                    <div className="col-6">
                                        <div className="icheck-primary">
                                            <input type="checkbox" id="remember" />
                                            <label htmlFor="remember">
                                                Remember Me
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-6" style={{ marginTop: 10, textAlign: 'right' }}>
                                        <NavLink exact to="/forgotpassword" className="nav-link">
                                            Forgot password?
                                        </NavLink>
                                        {/* <a href="forgotpassword" title='Click here to reset your password'>Forgot password?</a> */}
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary btn-block signin">Sign In</button>
                                    </div>
                                </div>
                                {showLoader ? <Loader /> : ''}
                                <div className="row centered-line ml-1">
                                    <div></div>
                                    <span>OR</span>
                                    <div></div>
                                </div>
                                <div className='mt-4' style={{ justifyContent: "center", display: "flex", fontSize: "15px" }}>
                                    <a href='#' onClick={redirectGoogleURL}> LinkedIn </a>
                                </div>
                            </form>
                        </div>

                    </div>

                </div>

            </div>

        </section>

    );
}
export default Login;